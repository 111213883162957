import React from 'react';
import './VideoSection.scss';
import Fade from 'react-reveal/Fade';
import Content from '../Content';

class VideoSection extends React.Component {
    static defaultProps = {
        title: '',
        videoSrc: '',
        videoText: ''
    };


    render() {
        const {title, videoSrc, videoText} = this.props;

        return (
            <div className="VideoSection container pb-8">
                <Fade>
                {title && <h2 className="VideoSection--title">{title}</h2>}
                <hr></hr>

                <div className="flex flex-col sm:flex-row mt-8 items-center"
                     >

                    <Content className="VideoSection--Container--Text w-full sm:w-1/2 p-4" source={videoText}/>

                    <iframe src={videoSrc} className="w-full sm:w-1/2 p-4" height="273" frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                </div>
                </Fade>
            </div>
        )
    }
}

export default VideoSection


